import { TAG } from './constants';

export default class MediaData {
  constructor(tag, attributes) {
    this._tag = tag.toLowerCase();
    this._attributes = attributes;
  }

  get tag() {
    return this._tag;
  }

  get attributes() {
    return this._attributes;
  }

  get isImage() {
    return this.tag === TAG.image;
  }

  get isVideo() {
    return this.tag === TAG.video;
  }

  static createImage(attributes) {
    return new MediaData(TAG.image, attributes);
  }

  static createVideo(attributes) {
    return new MediaData(TAG.video, attributes);
  }

  static fromString(mediaDataString) {
    const mediaData = JSON.parse(mediaDataString);
    return new MediaData(mediaData.tag, mediaData.attributes);
  }

  toString() {
    return JSON.stringify({
      tag: this.tag,
      attributes: this.attributes,
    });
  }
}
