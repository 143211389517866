import Guid from 'guid';
import { ATTRIBUTE, FIELD_TYPE, TAG } from './constants';

function createAttributeInfo(tag, name, displayName, type, defaultValueOrGetDefaultValue, isVisible) {
  return {
    tag,
    name,
    displayName,
    type,
    getDefaultValue: typeof defaultValueOrGetDefaultValue === 'function'
      ? defaultValueOrGetDefaultValue
      : () => defaultValueOrGetDefaultValue,
    isVisible,
  };
}

function createImageAttributeInfo(name, displayName, type, defaultValueOrGetDefaultValue, isVisible = true) {
  return createAttributeInfo(TAG.image, name, displayName, type, defaultValueOrGetDefaultValue, isVisible);
}

function createVideoAttributeInfo(name, displayName, type, defaultValueOrGetDefaultValue, isVisible = true) {
  return createAttributeInfo(TAG.video, name, displayName, type, defaultValueOrGetDefaultValue, isVisible);
}

const IMAGE_ATTRIBUTE_INFO = [
  createImageAttributeInfo(ATTRIBUTE.src, 'Source', FIELD_TYPE.string, ''),
  createImageAttributeInfo(ATTRIBUTE.fileSrc, 'Image File', FIELD_TYPE.file, null, false),
  createImageAttributeInfo(ATTRIBUTE.existingSrc, 'Previously Uploaded', FIELD_TYPE.string, '', false),
  createImageAttributeInfo(ATTRIBUTE.id, 'Id', FIELD_TYPE.string, () => Guid.raw(), false),
  createImageAttributeInfo(ATTRIBUTE.title, 'Title', FIELD_TYPE.string, ''),
  createImageAttributeInfo(ATTRIBUTE.altText, 'Alt Text', FIELD_TYPE.string, ''),
  createImageAttributeInfo(ATTRIBUTE.public, 'Public', FIELD_TYPE.boolean, false, false),
  createImageAttributeInfo(ATTRIBUTE.sizes, 'Sizes', FIELD_TYPE.list, ['x1024', 'x512', 'x256', 'x128', 'x64', 'orig'], true),
];

const VIDEO_ATTRIBUTE_INFO = [
  createVideoAttributeInfo(ATTRIBUTE.src, 'Source', FIELD_TYPE.string, ''),
  createVideoAttributeInfo(ATTRIBUTE.width, 'Width', FIELD_TYPE.number, 0),
  createVideoAttributeInfo(ATTRIBUTE.height, 'Height', FIELD_TYPE.number, 0),
  createVideoAttributeInfo(ATTRIBUTE.autoPlay, 'Auto Play', FIELD_TYPE.boolean, false),
  createVideoAttributeInfo(ATTRIBUTE.autoRepeat, 'Auto Repeat', FIELD_TYPE.boolean, false),
  createVideoAttributeInfo(ATTRIBUTE.timeVisible, 'Time Visible', FIELD_TYPE.boolean, true),
  createVideoAttributeInfo(ATTRIBUTE.pausable, 'Pausable', FIELD_TYPE.boolean, true),
];

export default function getAttributes(tag) {
  const lowerCaseTag = tag.toLowerCase();
  if (lowerCaseTag === TAG.image) {
    return IMAGE_ATTRIBUTE_INFO;
  }

  if (lowerCaseTag === TAG.video) {
    return VIDEO_ATTRIBUTE_INFO;
  }

  throw new Error(`Unexpected tag: '${tag}'`);
}
