<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="500"
  >
    <v-card>
      <v-card-title>{{ options.header }}</v-card-title>
      <v-card-text class="py-0">
        <v-container
          grid-list-md
          pa-0
        >
          <v-layout
            row
            align-start
            dense
            wrap
          >
            <v-flex
              xs12
            >
              <v-text-field
                v-model="text"
                :readonly="options.readonlyText"
                label="Text"
                hide-details
                solo-invert
                text
              />
            </v-flex>

            <v-flex xs12>
              <v-text-field
                v-model="url"
                label="URL"
                hide-details
                solo-invert
                text
              />
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-if="options.allowRemoveLink"
          color="primary"
          text="text"
          @click="removeLink"
        >Remove link</v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          text="text"
          @click="save"
        >Save</v-btn>
        <v-btn
          color="grey"
          text="text"
          @click="cancel"
        >Cancel</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>

const DEFAULT_OPTIONS = {
  allowRemoveLink: false,
  readonlyText: false,
  header: 'Link',
};

export default {
  data() {
    return {
      dialog: false,
      text: '',
      originalText: '',
      url: '',
      options: {
        ...DEFAULT_OPTIONS,
      },
    };
  },
  methods: {
    show(text, url, options) {
      if (this.dialog) {
        throw new Error('EditCustomLinkPopup is already shown');
      }

      this.text = text;
      this.originalText = this.text;
      this.url = url;
      this.options = {
        ...DEFAULT_OPTIONS,
        ...options,
      };

      this.dialog = true;

      return new Promise((resolve) => {
        this.$options.resolve = resolve;
      });
    },
    save() {
      this.close({ text: this.text, url: this.url });
    },
    cancel() {
      this.close(null);
    },
    removeLink() {
      this.close({ text: this.originalText });
    },
    close(newData) {
      this.dialog = false;
      this.$options.resolve(newData);
    },
  },
};
</script>
