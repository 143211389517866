import Guid from 'guid';
import getAttributes from './media/attributes';
import { getMediaElements, createMediaElement } from './media';
import { TAG } from './media/constants';

const TAG_NAME = {
  span: 'SPAN',
  p: 'P',
  li: 'LI',
  ol: 'OL',
  ul: 'UL',
  say: 'SAY',
  mute: 'MUTE',
  br: 'BR',
};
const NON_COLORABLE_TAGS = [TAG_NAME.p, TAG_NAME.li];

const LEADING_SPACES_REGEX = /^\s+/;

const DEFAULT_TO_READABLE_OPTIONS = {
  hideSayTagContent: false,
  hideMuteTagContent: false,
};

const fromQuillConverter = {
  _parser: new DOMParser(),

  convert(quillHtml) {
    if (!quillHtml) {
      return quillHtml;
    }

    const quillHtmlDocument = this._parser.parseFromString(quillHtml, 'text/html');

    this._replaceMedia(quillHtmlDocument);
    this._replaceColor(quillHtmlDocument);
    this._fixAndMergeParagraphs(quillHtmlDocument);
    this._unwrapSingleParagraph(quillHtmlDocument);

    let unityHtml = quillHtmlDocument.body.innerHTML;

    unityHtml = this._fixImageTags(unityHtml);
    unityHtml = this._fixBrs(unityHtml);

    return unityHtml;
  },

  _replaceMedia(quillHtmlDocument) {
    getMediaElements(quillHtmlDocument).forEach(({ node, data }) => {
      const unityMedia = quillHtmlDocument.createElement(data.tag);
      getAttributes(data.tag).map(a => a.name).forEach((attributeName) => {
        const attributeValue = data.attributes[attributeName];
        if (attributeValue !== null && attributeValue !== undefined) {
          unityMedia.setAttribute(attributeName, attributeValue);
        }
      });

      node.parentNode.replaceChild(unityMedia, node);
    });
  },

  _replaceColor(quillHtmlDocument) {
    let coloredElement = this._getColoredElement(quillHtmlDocument);
    while (coloredElement) {
      const color = quillHtmlDocument.createElement('color');
      color.setAttribute('value', coloredElement.color);
      color.innerHTML = coloredElement.element.innerHTML;

      coloredElement.removeColor();

      if (coloredElement.element.tagName === TAG_NAME.span && !coloredElement.element.attributes.length) {
        coloredElement.element.after(color);
        coloredElement.element.remove();
      } else {
        coloredElement.element.innerHTML = color.outerHTML;
      }

      coloredElement = this._getColoredElement(quillHtmlDocument);
    }
  },

  _getColoredElement(quillHtmlDocument) {
    const elements = quillHtmlDocument.querySelectorAll('[style*="color:"]');
    if (!elements.length) {
      return null;
    }

    for (let i = 0; i < elements.length; i += 1) {
      const element = elements[i];

      const style = element.getAttribute('style');
      const styles = style.split(';')
        .map(s => s.trim())
        .filter(s => s);
      const match = styles
        .map(s => s.match(/^\s*color: ?(rgb[(][0-9]{1,3}, ?[0-9]{1,3}, ?[0-9]{1,3}[)])\s*;?\s*$/))
        .find(m => m);
      if (match) {
        const removeColor = styles.length > 1
          ? () => { element.setAttribute('style', styles.filter(s => s !== match[0]).join(';')); }
          : () => { element.removeAttribute('style'); };

        return { element, color: match[1], removeColor };
      }
    }

    return null;
  },

  _fixAndMergeParagraphs(quillHtmlDocument) {
    [...quillHtmlDocument.getElementsByTagName('p')]
      .filter(p => p.innerHTML === '<br>')
      .forEach((p) => { p.innerHTML = ''; });

    let isAnythingMerged;
    do {
      isAnythingMerged = this._mergeParagraphs(quillHtmlDocument);
    } while (isAnythingMerged);
  },

  _unwrapSingleParagraph(quillHtmlDocument) {
    if (quillHtmlDocument.body.childElementCount > 1) {
      return;
    }

    // remove root p-tag
    const rootParagraph = quillHtmlDocument.body.firstChild;
    if (rootParagraph.tagName === TAG_NAME.p) {
      quillHtmlDocument.body.innerHTML = rootParagraph.innerHTML;
    }
  },

  _mergeParagraphs(quillHtmlDocument) {
    const paragraphs = quillHtmlDocument.getElementsByTagName('p');
    for (let i = 0; i < paragraphs.length; i += 1) {
      const paragraph = paragraphs[i];

      let sibling = paragraph.nextSibling;
      if (!sibling || sibling.tagName !== TAG_NAME.p) {
        continue; // eslint-disable-line no-continue
      }

      do {
        const siblingParagraph = sibling;
        paragraph.innerHTML = `${paragraph.innerHTML}<br/>${siblingParagraph.innerHTML}`;
        sibling = sibling.nextSibling;
        siblingParagraph.remove();
      } while (sibling && sibling.tagName === TAG_NAME.p);

      // we cannot continue because quillHtmlDocument structure is changed
      return true;
    }

    return false;
  },

  _fixImageTags(unityHtml) {
    return unityHtml.replace(/<img([^>]*)>/g, '<img$1/>');
  },

  _fixBrs(unityHtml) {
    return unityHtml.replace(/<br>/g, '<br/>');
  },
};

const toQuillConverter = {
  _parser: new DOMParser(),

  convert(unityHtml) {
    if (!unityHtml) {
      return unityHtml;
    }

    const quillHtmlDocument = this._parser.parseFromString(unityHtml, 'text/html');

    this._replaceColor(quillHtmlDocument);
    this._replaceMedia(quillHtmlDocument);
    this._fixParagraphsAndBrs(quillHtmlDocument, unityHtml);

    let quillHtml = quillHtmlDocument.body.innerHTML;

    quillHtml = this._fixBrs(quillHtml);

    return quillHtml;
  },

  _replaceColor(quillHtmlDocument) {
    [...quillHtmlDocument.getElementsByTagName('color')].forEach((color) => {
      const { parentNode } = color;
      if (parentNode.innerHTML === color.outerHTML && !NON_COLORABLE_TAGS.includes(parentNode.tagName)) {
        const newStyles = [];

        const existingStyle = parentNode.getAttribute('style');
        if (existingStyle) {
          newStyles.push(...existingStyle.split(';').map(s => s));
        }

        newStyles.push(`color: ${color.getAttribute('value')};`);

        parentNode.setAttribute('style', newStyles.join('; '));
        parentNode.innerHTML = color.innerHTML;
      } else {
        const span = quillHtmlDocument.createElement('span');
        span.setAttribute('style', `color: ${color.getAttribute('value')};`);
        span.innerHTML = color.innerHTML;

        color.parentNode.replaceChild(span, color);
      }
    });
  },

  _replaceMedia(quillHtmlDocument) {
    [
      ...quillHtmlDocument.getElementsByTagName(TAG.image),
      ...quillHtmlDocument.getElementsByTagName(TAG.video),
    ].forEach((media) => {
      const attributes = {};
      getAttributes(media.tagName).map(a => a.name).forEach((attributeName) => {
        const attributeValue = media.getAttribute(attributeName);
        if (attributeValue !== null && attributeValue !== undefined) {
          attributes[attributeName] = attributeValue;
        }
      });

      media.parentNode.replaceChild(createMediaElement(media.tagName, attributes), media);
    });
  },

  _fixParagraphsAndBrs(quillHtmlDocument, unityHtml) {
    this._wrapIntoParagraphs(quillHtmlDocument, unityHtml);

    const brsToReplace = this._getBrsToReplace(quillHtmlDocument);
    if (brsToReplace.length) {
      let newHtml = quillHtmlDocument.body.innerHTML;
      brsToReplace.forEach(({ br, replacement }) => {
        newHtml = newHtml.replace(br, replacement);
      });

      quillHtmlDocument.body.innerHTML = newHtml;
    }

    [...quillHtmlDocument.body.getElementsByTagName('p')]
      .filter(p => !p.innerHTML)
      .forEach((p) => { p.innerHTML = '<br>'; });
  },

  _wrapIntoParagraphs(quillHtmlDocument, unityHtml) {
    const nodesToMerge = [];

    const leadingSpaces = this._getLeadingSpaces(unityHtml);
    if (leadingSpaces) {
      quillHtmlDocument.body.prepend(quillHtmlDocument.createTextNode(leadingSpaces));
    }

    [...quillHtmlDocument.body.childNodes].forEach((node) => {
      if (!(node instanceof HTMLElement) || ![TAG_NAME.p, TAG_NAME.ol, TAG_NAME.ul].includes(node.tagName)) {
        nodesToMerge.push(node);
      } else {
        this._mergeAndWrapIntoParagraphs(quillHtmlDocument, nodesToMerge);
        nodesToMerge.splice(0, nodesToMerge.length);
      }
    });

    this._mergeAndWrapIntoParagraphs(quillHtmlDocument, nodesToMerge);
  },

  _getLeadingSpaces(unityHtml) {
    const result = LEADING_SPACES_REGEX.exec(unityHtml);
    return result ? result[0] : null;
  },

  _mergeAndWrapIntoParagraphs(quillHtmlDocument, nodes) {
    if (nodes.length) {
      const paragraph = quillHtmlDocument.createElement('p');
      nodes[0].parentNode.insertBefore(paragraph, nodes[0]);

      nodes.forEach((n) => {
        paragraph.innerHTML = `${paragraph.innerHTML}${n.outerHTML || n.textContent}`;
        n.remove();
      });
    }
  },

  _getBrsToReplace(quillHtmlDocument) {
    const brsToReplace = [];
    [...quillHtmlDocument.getElementsByTagName('br')].forEach((br) => {
      const parentNodes = [];

      let { parentNode } = br;
      while (parentNode && parentNode !== quillHtmlDocument.body) {
        parentNodes.push(parentNode);
        if (parentNode.tagName === TAG_NAME.p) {
          break;
        }

        parentNode = parentNode.parentNode;
      }

      if (parentNodes.length && parentNodes[parentNodes.length - 1].tagName === TAG_NAME.p) {
        br.setAttribute('mark', Guid.raw());
        brsToReplace.push({
          br: br.outerHTML,
          replacement: [
            ...parentNodes.map(n => `</${n.tagName.toLowerCase()}>`),
            ...[...parentNodes].reverse().map(n => `<${n.tagName.toLowerCase()}>`),
          ].join(''),
        });
      }
    });

    return brsToReplace;
  },

  _fixBrs(unityHtml) {
    return unityHtml.replace(/<br\/>/g, '<br>');
  },
};

const toReadableConverter = {
  _parser: new DOMParser(),

  convert(unityHtml, options) {
    if (!unityHtml) {
      return unityHtml;
    }

    const fullOptions = {
      ...DEFAULT_TO_READABLE_OPTIONS,
      ...(options || {}),
    };

    const readableStringDocument = this._parser.parseFromString(unityHtml, 'text/html');

    if (fullOptions.hideSayTagContent) {
      this._remove(readableStringDocument, TAG_NAME.say);
    }

    if (fullOptions.hideMuteTagContent) {
      this._remove(readableStringDocument, TAG_NAME.mute);
    }

    this._replaceBrBySpaces(readableStringDocument);

    let readableString = readableStringDocument.body.innerHTML;

    readableString = this._removeTags(readableString);

    return readableString;
  },

  _remove(readableStringDocument, tagName) {
    [...readableStringDocument.getElementsByTagName(tagName)].forEach((say) => {
      say.parentNode.removeChild(say);
    });
  },

  _replaceBrBySpaces(readableStringDocument) {
    [...readableStringDocument.getElementsByTagName(TAG_NAME.br)].forEach((br) => {
      br.parentNode.replaceChild(readableStringDocument.createTextNode(' '), br);
    });
  },

  _removeTags(readableString) {
    return readableString.replace(/<[^>]*>/g, '');
  },
};

export default {
  fromQuill(quillHtml) {
    return fromQuillConverter.convert(quillHtml);
  },

  toQuill(unityHtml) {
    return toQuillConverter.convert(unityHtml);
  },

  toReadable(unityHtml, options = null) {
    return toReadableConverter.convert(unityHtml, options);
  },
};
