<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="500"
  >
    <v-card
      v-if="dialog"
    >
      <v-form>
        <template v-if="header">
          <v-card-title v-if="header.title">{{ header.title }}</v-card-title>
          <v-card-subtitle
            v-if="header.subTitle"
            v-html="header.subTitle"
          />
        </template>
        <v-card-text class="py-0">
          <v-container
            grid-list-md
            pa-0
          >
            <v-layout
              row
              align-start
              dense
              wrap
            >
              <template v-for="field in fields">
                <v-flex
                  v-if="field.isVisible"
                  :key="field.name"
                  xs12
                >
                  <component
                    :is="field.component.name"
                    v-model="field.value"
                    v-bind="field.component.props"
                    v-on="field.listeners"
                  />
                  <component
                    :is="field.subComponent.name"
                    v-if="field.subComponent"
                    v-bind="field.subComponent.props"
                    v-on="field.subListeners"
                  />
                </v-flex>
              </template>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-text class="pb-0">
          <v-card-subtitle
            v-for="error in errors"
            :key="error"
            class="pa-0 red--text"
          >
            {{ error }}
          </v-card-subtitle>
          <v-card-subtitle
            v-for="warning in warnings"
            :key="warning"
            class="pa-0 yellow--text"
          >
            {{ warning }}
          </v-card-subtitle>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            :disabled="errors.length > 0"
            color="primary"
            text="text"
            @click.native="save"
          >Save</v-btn>
          <v-btn
            color="grey"
            text="text"
            @click.native="cancel"
          >Cancel</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  VTextField, VCheckbox, VFileInput, VSelect,
} from 'vuetify/lib';
import getAttributes from './attributes';
import MediaData from './MediaData';
import {
  FIELD_TYPE, REGEX_SIZE, REGEX_FILE_EXT, REGEX_FILENAME, ATTRIBUTE, NUMERIC_DEFAULT_SIZES,
} from './constants';
import SelectedImageItem from './SelectedImageItem';

function createHeader(title, subTitle) {
  return {
    title,
    subTitle,
  };
}
function isDefinedAndHasValue(variable) {
  return variable !== null && variable !== undefined;
}

const EDITABLE_IMG_FIELDS = {
  src: false,
  filesrc: false,
  id: false,
  alttext: true,
  title: true,
  isPublic: true,
  existingsrc: false,
};
const FIELDS_EDITABLE_LOCALLY = [ATTRIBUTE.src, ATTRIBUTE.sizes];

export default {
  props: {
    isFieldVisible: {
      type: Function,
      default: attribute => null, // eslint-disable-line no-unused-vars
    },
    uploadedImgData: {
      type: Object,
      default: null,
    },
    editingAllowed: {
      type: Boolean,
      default: true,
    },
    projectIdentifierAvailable: {
      type: Boolean,
      default: false,
    },
    selectedImageData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
      isImage: false,
      isVideo: false,
      fields: [],
      isEditing: false,
      saveAttrValues: {},
      size: 'orig',
      initialMediaData: {},
      extraImgProps: {
        filesrc: {
          hint: 'Only jpg and png images are supported',
          persistentHint: true,
          hideDetails: false,
          soloInverted: false,
        },
        src: {
          hint: 'URLs have to start with "https://". Only jpg and png images are supported.',
          persistentHint: true,
          hideDetails: false,
          soloInverted: false,
        },
        existingsrc: {
          hint: 'Image previously uploaded to builder..',
          persistentHint: true,
          hideDetails: false,
          soloInverted: false,
          readonly: true,
          prependIcon: 'folder',
        },
        title: {
          hint: 'Will be shown in the Media tab of the LabPad and the image library',
          persistentHint: true,
          hideDetails: false,
          soloInverted: false,
        },
        alttext: {
          hint: 'Will be read out loud in accessibility mode',
          persistentHint: true,
          hideDetails: false,
          soloInverted: false,
        },
      },
    };
  },
  computed: {
    header() {
      if (this.isImage) {
        const src = this.fields.find(f => f.name === ATTRIBUTE.src);
        if (this.isEditing) {
          return createHeader('Image', src.value);
        }
        const addingUnavailable = !this.projectIdentifierAvailable
        && !this.fields.find(f => f.name === ATTRIBUTE.fileSrc).isVisible
        && !src.isVisible;
        if (addingUnavailable) {
          return createHeader(
            'Image',
            'Adding unavailable: project identifier is missing.',
          );
        }
        if (src.isVisible) {
          return createHeader(
            'Image',
            this.projectIdentifierAvailable
              ? 'Please paste an image URL in the source field or upload it from your device using fileinput below.'
              : 'Please paste an image URL in the source field below.',
          );
        }
        return createHeader(
          'Image',
          'Please upload an image using fileinput below.',
        );
      }

      if (this.isVideo) {
        return createHeader('Video');
      }

      return null;
    },
    errors() {
      const errors = [];

      if (this.isImage) {
        if (!this.existingSrcValue) {
          if (this.fileSrcValue) {
            errors.push(...this.imgFileSrcErrors);
          } else if (this.srcValue) {
            errors.push(...this.imgSrcErrors);
          } else {
            errors.push('Image is not set');
          }
        }

        if ((!this.titleValue || !this.titleValue.trim()) && this.projectIdentifierAvailable) {
          errors.push('Title is required');
        }
      } else if (this.isVideo) {
        if (!this.srcValue || !this.srcValue.trim()) {
          errors.push('Video URL is not set');
        }
      } else {
        errors.push('Unexpected media type');
      }

      return errors;
    },
    warnings() {
      const warnings = [];
      if (this.isImage && !this.isEditing && !this.projectIdentifierAvailable) {
        warnings.push('You cannot upload an image from your computer because there is no Transifex Project defined in the Engine XML.');
      }
      return warnings;
    },
    isImageManager() {
      return this.isEditing
        ? this.saveAttrValues.src.includes('labster-image-manager')
        : this.fields.find(f => [ATTRIBUTE.existingSrc, ATTRIBUTE.src].includes(f.name) && f.value)
          .value.includes('labster-image-manager');
    },
    srcValue() {
      return this.getFieldValueByName(ATTRIBUTE.src, '');
    },
    titleValue() {
      return this.getFieldValueByName(ATTRIBUTE.title, '');
    },
    fileSrcValue() {
      return this.getFieldValueByName(ATTRIBUTE.fileSrc, null);
    },
    existingSrcValue() {
      return this.getFieldValueByName(ATTRIBUTE.existingSrc, '');
    },
    imgSrcErrors() {
      const imgSrcErrors = [];
      if (!this.srcValue.startsWith('https://')) {
        imgSrcErrors.push('Image URL should start with https://');
      }

      if (!this.srcValue.endsWith('.jpg') && !this.srcValue.endsWith('.png')) {
        imgSrcErrors.push('Only jpg and png images are supported');
      }
      return imgSrcErrors;
    },
    imgFileSrcErrors() {
      const imgFileSrcErrors = [];
      if (!['png', 'jpg'].includes(REGEX_FILE_EXT.exec(this.fileSrcValue.name)[1])) {
        imgFileSrcErrors.push('Only jpg and png images are supported');
      }
      return imgFileSrcErrors;
    },
  },
  watch: {
    uploadedImgData(newValue) {
      if (newValue) {
        if (newValue.error) {
          this.resolveUpdates(null);
          return;
        }

        this.saveAttrValues.src = newValue.url_en;
        this.setSizeInUrl(this.size);

        const newAttributes = {
          title: newValue.title,
          alttext: newValue.alt,
          id: newValue.uuid,
          src: this.saveAttrValues.src,
          public: newValue.public,
          sizes: this.saveAttrValues.sizes,
        };
        const mediaData = MediaData.createImage(newAttributes);
        this.resolveUpdates(mediaData);
      }
    },
    selectedImageData(imgData) {
      if (imgData) {
        this.saveAttrValues.src = imgData.mediaData.src;
        this.setSizeInUrl(this.size);
        this.hideAndResetFields(ATTRIBUTE.src, ATTRIBUTE.fileSrc);
        this.fields = this.fields.map((f) => {
          if (f.name === ATTRIBUTE.existingSrc) {
            f.value = imgData.mediaData.src;
            f.listeners = {};
            f.subComponent = {};
            f.subComponent.name = SelectedImageItem;
            f.subComponent.props = { displayName: imgData.displayName, src: imgData.mediaData.src };
            f.subListeners = { 'reset-selection': this.resetImageSelection };
          } else if (f.name !== ATTRIBUTE.src && isDefinedAndHasValue(imgData.mediaData[f.name])) {
            f.value = imgData.mediaData[f.name];
          } else {
            f.isVisible = false;
          }
          return f;
        });
      }
    },
    srcValue(value) {
      if (this.isImage && value && !this.imgSrcErrors.length) {
        this.hideAndResetFields(ATTRIBUTE.fileSrc, ATTRIBUTE.existingSrc);
      } else {
        this.showHiddenFields();
      }
    },
    numericSizeValue() {
      return this.size && this.size !== 'orig' ? Number(this.size.replace('x', '')) : 0;
    },
    imageManagerAvailableSizes() {
      if (this.numericSizeValue && this.isImageManager) {
        return NUMERIC_DEFAULT_SIZES.reduce(
          (curr, acc) => ((curr <= this.numericSizeValue) ? [...acc, curr] : [...acc]),
          ['orig'],
        );
      }
      return ['orig'];
    },
  },
  methods: {
    async show(mediaData) {
      if (this.dialog) {
        throw new Error('EditMediaPopup is already shown');
      }

      this.isEditing = mediaData.isImage && !!mediaData.attributes.id;
      this.isImage = mediaData.isImage;
      this.isVideo = mediaData.isVideo;
      this.saveAttrValues = { ...mediaData.attributes };

      if (this.isEditing && !this.saveAttrValues.sizes && this.isImageManager) {
        const filesrc = await this.urlToFile(this.saveAttrValues.src);
        if (filesrc) {
          await this.getAvailableSizesFromFile(filesrc);
        }
      }

      this.fields = getAttributes(mediaData.tag).map((a) => {
        const isVisible = (this.isEditing && a.name !== ATTRIBUTE.public)
          ? EDITABLE_IMG_FIELDS[a.name]
          : this.isFieldVisible(a);
        return this.createField(mediaData, a, isVisible);
      });
      this.dialog = true;

      return new Promise((resolve) => {
        this.$options.resolve = resolve;
      });
    },
    async save() {
      const newAttributes = {};
      let isImageManagerUpdate = false;
      this.fields.forEach((f) => {
        if (!this.isEditing || !FIELDS_EDITABLE_LOCALLY.includes(f.name)) {
          newAttributes[f.name] = f.value;
          if (!isImageManagerUpdate) {
            isImageManagerUpdate = String(newAttributes[f.name]) !== String(this.saveAttrValues[f.name]);
          }
        } else {
          newAttributes[f.name] = this.saveAttrValues[f.name];
        }
      });

      let { filesrc } = newAttributes;
      const { src, existingsrc } = newAttributes;
      this.size = 'orig';
      let uploadSrc = existingsrc || src;

      if (!this.isEditing && !filesrc) {
        if (this.isImageManager) {
          this.size = this.getSizeFromUrl(uploadSrc);
          uploadSrc = this.size === 'orig' ? uploadSrc : uploadSrc.replace(`.${this.size}.`, '.');
        }
        filesrc = await this.urlToFile(uploadSrc);
      }

      if (this.isImage) {
        if (this.isEditing) {
          if (isImageManagerUpdate) {
            this.$emit(
              'image-update',
              {
                id: newAttributes.id,
                src: uploadSrc,
                title: newAttributes.title,
                alttext: newAttributes.alttext,
                isPublic: newAttributes.public,
              },
            );
          }
          const mediaData = MediaData.createImage(newAttributes);
          this.resolveUpdates(mediaData);
        } else if (this.projectIdentifierAvailable && filesrc && !existingsrc) {
          await this.getAvailableSizesFromFile(filesrc);
          this.$emit(
            'file-upload',
            {
              id: newAttributes.id,
              title: newAttributes.title,
              alttext: newAttributes.alttext,
              isPublic: newAttributes.public,
              filesrc,
            },
          );
        } else if (uploadSrc) {
          if (filesrc && existingsrc) {
            await this.getAvailableSizesFromFile(filesrc);
          } else {
            this.saveAttrValues.sizes = this.imageManagerAvailableSizes;
          }
          this.$emit(
            'image-update',
            {
              id: newAttributes.id,
              src: uploadSrc,
              title: newAttributes.title,
              alttext: newAttributes.alttext,
              isPublic: newAttributes.public,
            },
          );
          const mediaData = MediaData.createImage({
            id: newAttributes.id,
            src: uploadSrc,
            title: newAttributes.title,
            alttext: newAttributes.alttext,
            sizes: this.saveAttrValues.sizes,
          });
          this.resolveUpdates(mediaData);
        } else {
          throw new Error('Error saving Image: no image set');
        }
      } else if (this.isVideo) {
        const mediaData = MediaData.createVideo(newAttributes);
        this.resolveUpdates(mediaData);
      } else {
        throw new Error('Unexpected Media type');
      }
      this.hideDialog();
    },
    cancel() {
      this.hideDialog();
      this.resolveUpdates(null);
    },
    hideDialog() {
      this.dialog = false;
    },
    resolveUpdates(mediaData) {
      this.$options.resolve(mediaData);
    },
    createField(mediaData, attribute, isVisible) {
      const component = {};

      let value;
      let isFieldVisible = isVisible;
      switch (attribute.type) {
        case FIELD_TYPE.string: {
          component.name = VTextField;
          component.props = {
            hideDetails: true,
            soloInverted: true,
            text: true,
          };
          value = mediaData.attributes[attribute.name] || attribute.getDefaultValue();
          break;
        }
        case FIELD_TYPE.number: {
          component.name = VTextField;
          component.props = {
            hideDetails: true,
            soloInverted: true,
            text: true,
            type: 'number',
          };
          const numberValue = parseFloat(mediaData.attributes[attribute.name]);
          value = !Number.isNaN(numberValue) ? numberValue : attribute.getDefaultValue();
          break;
        }
        case FIELD_TYPE.boolean: {
          component.name = VCheckbox;
          component.props = { hideDetails: true };
          const rawValue = mediaData.attributes[attribute.name];
          if (attribute.name === ATTRIBUTE.public) {
            value = this.getPublicValue(rawValue, isVisible);
            isFieldVisible = value === null ? false : isVisible;
          } else {
            value = !isDefinedAndHasValue(rawValue) || typeof rawValue !== 'string'
              ? attribute.getDefaultValue()
              : rawValue.toLowerCase() === 'true';
          }
          break;
        }
        case FIELD_TYPE.file: {
          component.name = VFileInput;
          component.props = { accept: 'image/png, image/jpeg' };
          value = mediaData.attributes[attribute.name] || attribute.getDefaultValue();
          break;
        }
        case FIELD_TYPE.list: {
          component.name = VSelect;
          const rawItems = mediaData.attributes[attribute.name] || this.saveAttrValues.sizes;
          const items = typeof rawItems === 'string' ? rawItems.split(',') : rawItems;
          component.props = { items };
          value = this.saveAttrValues.src && this.isImageManager
            ? this.getSizeFromUrl(this.saveAttrValues.src)
            : null;
          isFieldVisible = Boolean(value);
          break;
        }
        default: {
          throw new Error(`Unexpected Field Type: ${attribute.type}`);
        }
      }

      component.props.label = attribute.displayName;
      if (this.isDisabled(attribute)) {
        component.props.disabled = true;
      }
      component.props = this.isImage
        ? { ...component.props, ...this.extraImgProps[attribute.name] }
        : { ...component.props };
      return {
        name: attribute.name,
        isVisible: typeof isFieldVisible === 'boolean' ? isFieldVisible : attribute.isVisible,
        isVisibleOnCreate: typeof isFieldVisible === 'boolean' ? isFieldVisible : attribute.isVisible,
        valueOnCreate: value,
        value,
        listeners: this.getInitialFieldListeners(attribute.type, attribute.name),
        component,
      };
    },
    fileUpdateListener(file) {
      if (file && !this.imgFileSrcErrors.length) {
        this.hideAndResetFields(ATTRIBUTE.src, ATTRIBUTE.existingSrc);
      } else {
        this.showHiddenFields();
      }
    },
    hideAndResetFields(...fieldNames) {
      this.fields = this.fields.map((f) => {
        if (fieldNames.includes(f.name)) {
          f.isVisible = false;
          f.value = f.valueOnCreate;
        }
        return f;
      });
    },
    showHiddenFields() {
      this.fields = this.fields.map(f => ({ ...f, isVisible: f.isVisibleOnCreate, value: f.valueOnCreate }));
    },
    getPublicValue(rawValue, isFieldVisible) {
      if (typeof rawValue === 'string') {
        return rawValue.toLowerCase() === 'true';
      } if (!isDefinedAndHasValue(rawValue)) {
        return (this.projectIdentifierAvailable && !this.isEditing) ? isFieldVisible : null;
      }
      return rawValue;
    },
    async urlToFile(url) {
      try {
        const response = await fetch(url);
        const blob = await response.blob();
        const filename = url.match(REGEX_FILENAME)[0];
        const file = new File([blob], filename, { type: blob.type });
        return file;
      } catch (e) {
        return null;
      }
    },
    getSizeFromUrl(src) {
      const sizeMatch = src.match(REGEX_SIZE);
      return sizeMatch ? sizeMatch[0].replaceAll('.', '') : 'orig';
    },
    setSizeInUrl(value) {
      const sizeMatch = this.saveAttrValues.src.match(REGEX_SIZE);
      if (value === 'orig' || value === '') {
        this.saveAttrValues.src = sizeMatch ? this.saveAttrValues.src.replace(sizeMatch[0], '.') : this.saveAttrValues.src;
      } else if (sizeMatch) {
        this.saveAttrValues.src = this.saveAttrValues.src.replace(sizeMatch[0], `.${value}.`);
      } else {
        const pos = this.saveAttrValues.src.indexOf(REGEX_FILE_EXT.exec(this.saveAttrValues.src)[0]);
        this.saveAttrValues.src = [this.saveAttrValues.src.slice(0, pos), `.${value}`, this.saveAttrValues.src.slice(pos)].join('');
      }
    },
    isDisabled(attr) {
      if (attr.name === 'sizes') {
        return false;
      }
      if (this.isEditing) {
        if (!isDefinedAndHasValue(this.saveAttrValues.public)) {
          return false;
        }
        if (!this.editingAllowed) {
          return true;
        }
      }
      return false;
    },
    getAvailableSizesFromFile(file) {
      return new Promise((resolve, reject) => {
        try {
          const fr = new FileReader();

          fr.onload = () => {
            const img = new Image();
            img.onload = () => {
              const w = img.width;
              const h = img.height;
              this.saveAttrValues.sizes = NUMERIC_DEFAULT_SIZES.reduce(
                (resArr, currSize) => ((w >= currSize || h >= currSize) ? [...resArr, `x${currSize}`] : [...resArr]),
                ['orig'],
              );
              resolve();
            };
            img.src = fr.result;
          };
          fr.readAsDataURL(file);
        } catch (e) {
          reject(e);
        }
      });
    },
    getFieldValueByName(fieldName, defaultValue) {
      const field = this.fields.find(f => f.name === fieldName);
      return field ? field.value : defaultValue;
    },
    showUploadedImages() {
      this.$emit('show-uploaded-images');
    },
    getInitialFieldListeners(fieldType, fieldName) {
      const listeners = {};
      switch (fieldType) {
        case FIELD_TYPE.file: {
          listeners.change = this.fileUpdateListener;
          break;
        }
        case FIELD_TYPE.list: {
          listeners.change = this.setSizeInUrl;
          break;
        }
        default: {
          break;
        }
      }
      switch (fieldName) {
        case ATTRIBUTE.existingSrc: {
          listeners.click = this.showUploadedImages;
          break;
        }
        default: {
          break;
        }
      }
      return listeners;
    },
    resetImageSelection() {
      this.showHiddenFields();
      this.fields = this.fields.map((f) => {
        if (f.name === ATTRIBUTE.existingSrc) {
          f.value = '';
          f.listeners = { click: this.showUploadedImages };
          f.subComponent = null;
          f.subListeners = null;
        }
        return f;
      });
    },
  },
};
</script>
