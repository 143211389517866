import Quill from 'quill';
import imageIcon from './icons/image';
import videoIcon from './icons/video';
import MediaData from './MediaData';
import { REGEX_SIZE } from './constants';

const Image = Quill.import('formats/image');

class Media extends Image {
  /* eslint-disable no-undef */
  static blotName = 'media';

  static className = 'ql-media';

  static tagName = 'img';
  /* eslint-enable */

  static create(mediaData) {
    if (!(mediaData instanceof MediaData)) {
      throw new Error(`Unexpected value for Media.create: ${mediaData.toString()}`);
    }

    let icon = '';
    if (mediaData.isImage) {
      icon = imageIcon;
    } else if (mediaData.isVideo) {
      icon = videoIcon;
    } else {
      throw new Error(`Unexpected Media Data: ${mediaData.toString()}`);
    }

    const node = super.create(`data:image/svg+xml,${icon}`);

    if (mediaData.isImage) {
      const size = mediaData.attributes.src && mediaData.attributes.src.includes('labster-image-manager') && mediaData.attributes.src.match(REGEX_SIZE);
      const actualSize = size ? size[0].replaceAll('.', '') : 'orig';
      node.setAttribute('title', actualSize);
    }

    node.setAttribute('width', '30');
    node.setAttribute('height', '30');
    node.setAttribute('data', mediaData.toString());

    return node;
  }

  static value(domNode) {
    return MediaData.fromString(domNode.getAttribute('data'));
  }
}

const IMAGE = 'image';
const VIDEO = 'video';

const MediaIcons = {
  [IMAGE]: imageIcon,
  [VIDEO]: videoIcon,
};

const MediaToolbarButtonsTooltips = [
  { class: 'ql-image', title: 'Insert an image URL' },
  { class: 'ql-video', title: 'Insert a video URL' },
];

function registerMediaEvents(quill) {
  quill.root.addEventListener('dblclick', ({ target }) => {
    if (target.matches(`${Media.tagName}.${Media.className}`)) {
      target.dispatchEvent(new CustomEvent('edit-ql-media', {
        bubbles: true,
        detail: {
          mediaData: MediaData.fromString(target.getAttribute('data')),
          update: newMediaData => target.setAttribute('data', newMediaData.toString()),
        },
      }));
    }
  });
}

function createMediaHandlers(quill, imageInserter, videoInserter) {
  const inserterCreator = createMediaData => (attributes) => {
    const range = quill.getSelection();
    const mediaData = createMediaData(attributes);
    quill.insertEmbed(range.index, Media.blotName, mediaData, Quill.sources.USER);
    quill.setSelection(range.index + 1, Quill.sources.SILENT);
  };

  const toolbar = quill.getModule('toolbar');
  toolbar.addHandler(IMAGE, () => {
    imageInserter(inserterCreator(MediaData.createImage));
  });
  toolbar.addHandler(VIDEO, () => {
    videoInserter(inserterCreator(MediaData.createVideo));
  });
}

function registerMedia(quill, imageInserter, videoInserter) {
  registerMediaEvents(quill);
  createMediaHandlers(quill, imageInserter, videoInserter);
}

function createMediaElement(tag, attributes) {
  return Media.create(new MediaData(tag, attributes));
}

function getMediaElements(document) {
  return [...document.getElementsByClassName(Media.className)].map(media => ({
    node: media,
    data: Media.value(media),
  }));
}

export {
  Media as MediaRegistration,
  MediaIcons,
  IMAGE as ImageToolbarButton,
  VIDEO as VideoToolbarButton,
  MediaToolbarButtonsTooltips,
  registerMedia,
  getMediaElements,
  createMediaElement,
};
