import RichTextEditor from 'RichTextEditor';
import { ATTRIBUTE, TAG } from './media/constants';
import htmlConverter from './htmlConverter';

export default RichTextEditor;

const htmlToReadable = htmlConverter.toReadable;
export {
  ATTRIBUTE,
  TAG,
  htmlToReadable,
};
