import Quill from 'quill';
import muteIcon from './icons/mute';
import sayIcon from './icons/say';

const Inline = Quill.import('blots/inline');

const MUTE = 'mute';
const SAY = 'say';

const MUTE_TAG = 'MUTE';
const SAY_TAG = 'SAY';

class Voice extends Inline {
  /* eslint-disable no-undef */
  static blotName = 'voice';

  static tagName = [MUTE_TAG, SAY_TAG];
  /* eslint-enable */

  static create(value) {
    if (value === MUTE) {
      return document.createElement(MUTE);
    }

    if (value === SAY) {
      return document.createElement(SAY);
    }

    return super.create(value);
  }

  static formats(domNode) {
    if (domNode.tagName === MUTE_TAG) {
      return MUTE;
    }

    if (domNode.tagName === SAY_TAG) {
      return SAY;
    }

    return undefined;
  }
}

// add voice to the end (as the highest in the order) to allow it to wrap all other formats
Inline.order.push(Voice.blotName);

const VoiceIcons = {
  [Voice.blotName]: {
    [MUTE]: muteIcon,
    [SAY]: sayIcon,
  },
};

const VoiceToolbarButtons = [
  { [Voice.blotName]: MUTE },
  { [Voice.blotName]: SAY },
];

const VoiceToolbarButtonTooltips = [{
  class: 'ql-voice',
  titles: {
    mute: 'Selected text will be displayed but no voice-over will be generated',
    say: 'Selected text will be spoken by the voice-over but the text won’t be displayed',
  },
}];

export {
  Voice as VoiceRegistration, VoiceIcons, VoiceToolbarButtons, VoiceToolbarButtonTooltips,
};
