<template>
  <v-tooltip bottom>
    <template #activator="{ on }">
      <v-card
        class="selected-image-tile"
        v-on="on"
        @mouseover="hovered=true"
        @mouseleave="hovered=false"
      >
        <v-btn
          v-if="hovered"
          class="selection-btn-icon del white--text"
          fab
          small
          color="red"
          @click="$emit('reset-selection')"
        >
          <v-icon>delete</v-icon>
        </v-btn>
        <v-btn
          v-else
          class="selection-btn-icon black--text"
          fab
          small
          color="white"
        >
          <v-icon>done</v-icon>
        </v-btn>
        <div class="selected-image">
          <v-img
            :src="src"
          />
        </div>
        <span class="selected-image-name">{{ displayName }}</span>
      </v-card>
    </template>
    <span class="selected-image-name">{{ displayName }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: true,
    },
    displayName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      hovered: false,
    };
  },
};
</script>
