import Quill from 'quill';

const Inline = Quill.import('blots/inline');

function sanitize(url) {
  const anchor = document.createElement('a');
  anchor.href = url;
  const protocol = anchor.href.slice(0, anchor.href.indexOf(':'));
  return ['http', 'https', 'mailto', 'tel'].indexOf(protocol) > -1
    ? url
    : 'about:blank';
}

// based on https://github.com/quilljs/quill/blob/develop/formats/link.js
class CustomLink extends Inline {
  /* eslint-disable no-undef */
  static blotName = 'custom-link';

  static className = 'ql-custom-link';

  static tagName = 'a';
  /* eslint-enable */

  static create(url) {
    const node = super.create();
    node.setAttribute('href', sanitize(url));
    node.setAttribute('rel', 'noopener noreferrer');
    node.setAttribute('target', '_blank');
    return node;
  }

  static formats(domNode) {
    return domNode.getAttribute('href');
  }

  format(name, value) {
    if (name !== this.statics.blotName || !value) {
      super.format(name, value);
    } else {
      this.domNode.setAttribute('href', sanitize(value));
    }
  }
}

const CustomLinkIcons = {
  [CustomLink.blotName]: Quill.import('ui/icons').link,
};

const CustomLinkToolbarButton = CustomLink.blotName;
const CustomLinkToolbarButtonTooltip = { class: 'ql-custom-link', title: 'Link' };

function registerCustomLinkEvent(quill) {
  quill.root.addEventListener('click', (event) => {
    const { target } = event;
    if (target.matches(`${CustomLink.tagName}.${CustomLink.className}`)) {
      event.preventDefault();
      target.dispatchEvent(new CustomEvent('edit-ql-custom-link', {
        bubbles: true,
        detail: {
          text: target.textContent,
          url: target.getAttribute('href'),
          update: (text, url) => {
            if (!text) {
              target.remove();
              return;
            }

            if (!url) {
              target.after(text);
              target.remove();
              return;
            }

            target.textContent = text;
            target.setAttribute('href', url);
          },
        },
      }));
    }
  });
}

function createCustomLinkHandler(quill, getUrl) {
  quill.getModule('toolbar').addHandler(CustomLink.blotName, async () => {
    const range = quill.getSelection();
    if (!range || !range.length) {
      return;
    }

    const url = await getUrl(quill.getText(range.index, range.length));
    if (!url) {
      return;
    }

    quill.formatText(
      range,
      CustomLink.blotName,
      url,
      Quill.sources.USER,
    );
  });
}

function registerCustomLink(quill, getUrl) {
  registerCustomLinkEvent(quill);
  createCustomLinkHandler(quill, getUrl);
}

export {
  CustomLink as CustomLinkRegistration,
  CustomLinkIcons,
  CustomLinkToolbarButton,
  CustomLinkToolbarButtonTooltip,
  registerCustomLink,
};
