<template>
  <div class="quill-editor">
    <div ref="editor" />
  </div>
</template>

<script>
import Quill from 'quill';

export default {
  props: {
    value: {
      required: true,
      validator: value => typeof value === 'string' || value === null || value === undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      content: '',
      quill: null,
    };
  },
  watch: {
    value: {
      handler() {
        this.content = this.value;
      },
      immediate: true,
    },
    content: {
      handler() {
        if (this.content !== this.value) {
          this.$emit('input', this.content);
        }

        if (this.quill) {
          if (this.content !== this.quill.root.innerHTML && !this.quill.hasFocus()) {
            this.quill.root.innerHTML = this.content || '';
          }
        }
      },
    },
    disabled: {
      handler() {
        if (this.quill) {
          this.quill.enable(!this.disabled);
        }
      },
    },
  },
  mounted() {
    this.quill = new Quill(this.$refs.editor, this.options);

    if (this.content) {
      this.quill.root.innerHTML = this.content;
    }

    if (this.disabled) {
      this.quill.disable();
    }

    this.quill.on('text-change', this.handleTextChange);
    this.quill.on('selection-change', this.handleSelectionChange);

    this.$emit('ready', this.quill);
  },
  beforeDestroy() {
    this.quill.off('text-change', this.handleTextChange);
    this.quill.off('selection-change', this.handleSelectionChange);

    this.quill = null;
    delete this.quill;
  },
  methods: {
    handleTextChange() {
      this.content = this.quill.root.innerHTML === '<p><br></p>' ? '' : this.quill.root.innerHTML;
    },
    handleSelectionChange(range, oldRange) {
      if (!range && oldRange) {
        this.$emit('blur', this.quill);
      } else if (range && !oldRange) {
        this.$emit('focus', this.quill);
      }
    },
  },
};
</script>
