import Quill from 'quill';
import DOMPurify from 'dompurify';

const Clipboard = Quill.import('modules/clipboard');
const Delta = Quill.import('delta');

const ALLOWED_TAG = ['strong', 'b', 'em', 'i', 's', 'sub', 'sup', 'p', 'br'];
const ADD_TAGS = ['mute', 'say'];
const ALLOWED_ATTR = [];

export default class PlainClipboard extends Clipboard {
  onPaste(e) {
    e.preventDefault();

    if (!this.quill.isEnabled()) {
      return;
    }

    const clipboardHtml = e.clipboardData.getData('text/html') || e.clipboardData.getData('text/plain');
    const sanitizedHtml = DOMPurify.sanitize(clipboardHtml, { ALLOWED_TAG, ADD_TAGS, ALLOWED_ATTR }).replace(/&nbsp;/g, '');

    const range = this.quill.getSelection();
    const delta = new Delta().retain(range.index).delete(range.length).concat(this.convert(sanitizedHtml));
    this.quill.updateContents(delta, 'api');
    this.quill.setSelection(range.index + sanitizedHtml.length, 0, 'silent');
    this.quill.scrollIntoView();
  }
}
