<template>
  <div>
    <QuillEditor
      ref="quillEditor"
      :key="editorOptions.key"
      v-model="content"
      :options="editorOptions"
      :disabled="disabled"
      :class="cssClasses"
      :style="cssVars"
      @focus="handleFocus"
      @blur="handleBlur"
      @ready="ready"
      @edit-ql-media.native="editMedia"
      @edit-ql-custom-link.native="editCustomLink"
    />

    <EditMediaPopup
      ref="editMediaPopup"
      :isFieldVisible="isMediaFieldVisible"
      :uploadedImgData="uploadedImgData"
      :editingAllowed="editingAllowed"
      :projectIdentifierAvailable="projectIdentifierAvailable"
      :selectedImageData="selectedImageData"
      @image-update="$emit('image-update', $event)"
      @file-upload="$emit('file-upload', $event)"
      @show-uploaded-images="$emit('show-uploaded-images')"
    />

    <EditCustomLinkPopup ref="editCustomLinkPopup" />
  </div>
</template>

<script>
import Guid from 'guid';
import Quill from 'quill';

import QuillEditor from './QuillEditor';
import EditMediaPopup from './media/EditMediaPopup';
import EditCustomLinkPopup from './custom-link/EditCustomLinkPopup';

import {
  MediaRegistration, MediaIcons, ImageToolbarButton, VideoToolbarButton, MediaToolbarButtonsTooltips, registerMedia,
} from './media';
import {
  VoiceRegistration, VoiceIcons, VoiceToolbarButtons, VoiceToolbarButtonTooltips,
} from './voice';
import {
  CustomLinkRegistration, CustomLinkIcons, CustomLinkToolbarButton, CustomLinkToolbarButtonTooltip, registerCustomLink,
} from './custom-link';

import MediaData from './media/MediaData';

import PlainClipboard from './PlainClipboard';

import htmlConverter from './htmlConverter';

Quill.register(MediaRegistration);
Quill.register(VoiceRegistration);
Quill.register(CustomLinkRegistration);

Quill.register('modules/clipboard', PlainClipboard, true);

const icons = Quill.import('ui/icons');
Object.assign(icons, MediaIcons, VoiceIcons, CustomLinkIcons);

export default {
  components: {
    QuillEditor,
    EditMediaPopup,
    EditCustomLinkPopup,
  },
  props: {
    value: {
      required: true,
      validator: value => typeof value === 'string' || value === null || value === undefined,
    },
    includeImage: {
      type: Boolean,
      default: false,
    },
    editingAllowed: {
      type: Boolean,
      default: true,
    },
    includeVideo: {
      type: Boolean,
      default: false,
    },
    includeVoice: {
      type: Boolean,
      default: false,
    },
    includeLink: {
      type: Boolean,
      default: false,
    },
    keyboardBinding: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    maxGrownHeight: {
      type: Number,
      default: -1,
    },
    quillEditorClass: {
      type: String,
      default: '',
    },
    isMediaFieldVisible: {
      type: Function,
      default: attribute => null, // eslint-disable-line no-unused-vars
    },
    disableHtmlConverting: {
      type: Boolean,
      default: false,
    },
    uploadedImgData: {
      type: Object,
      default: null,
    },
    projectIdentifierAvailable: {
      type: Boolean,
      default: false,
    },
    selectedImageData: {
      type: Object,
      default: null,
    },
    relevantImageData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      quill: null,
      toolbar: null,
      editor: null,
      isFocused: false,
      isPopupShowing: false,
      content: '',
      postponedUpdate: null,
    };
  },
  computed: {
    cssClasses() {
      const cssClasses = ['rich-text-editor'];

      if (this.disabled) {
        cssClasses.push('rich-text-editor-disabled');
      }

      if (this.isFocused) {
        cssClasses.push('rich-text-editor-focused');
      }

      if (this.quillEditorClass) {
        cssClasses.push(this.quillEditorClass);
      }

      return cssClasses;
    },
    cssVars() {
      let toolbarMinWidth = 158;

      if (this.includeVoice) {
        toolbarMinWidth += 52;
      }

      if (this.includeImage) {
        toolbarMinWidth += 26;
      }

      if (this.includeVideo) {
        toolbarMinWidth += 26;
      }

      if (this.includeLink) {
        toolbarMinWidth += 26;
      }

      return `--toolbar-min-width: ${toolbarMinWidth}px;`;
    },
    editorOptions() {
      const additionalButtons = [];

      if (this.includeVoice) {
        additionalButtons.push(...VoiceToolbarButtons);
      }

      if (this.includeImage) {
        additionalButtons.push(ImageToolbarButton);
      }

      if (this.includeVideo) {
        additionalButtons.push(VideoToolbarButton);
      }

      if (this.includeLink) {
        additionalButtons.push(CustomLinkToolbarButton);
      }

      return {
        key: Guid.raw(),
        theme: 'snow',
        placeholder: '',
        modules: {
          toolbar: {
            container: [
              [
                {
                  color: [
                    '',
                    '#75b0eb',
                    '#59656e',
                    '#eb7575',
                    '#fde545',
                    '#9eeb74',
                    '#b461e8',
                    '#f9ac34',
                  ],
                },
                'bold',
                'italic',
                'strike',
                { script: 'sub' },
                { script: 'super' },
                ...additionalButtons,
              ],
            ],
          },
          keyboard: {
            bindings: {
              tab: {
                handler: () => {
                  // do nothing
                },
              },
              indent: {
                handler: () => {
                  // do nothing
                },
              },
              ...this.keyboardBinding,
            },
          },
        },
      };
    },
  },
  watch: {
    value: {
      handler() {
        this.content = this.disableHtmlConverting ? this.value : htmlConverter.toQuill(this.value);
      },
      immediate: true,
    },
    content: {
      handler() {
        if (this.content !== this.value) {
          this.$emit('input', this.disableHtmlConverting ? this.content : htmlConverter.fromQuill(this.content));
        }

        this.$nextTick(() => {
          if (!this.isFocused && this.quill.hasFocus()) {
            this.blur();
          }
        });
      },
    },
    isFocused: {
      handler() {
        this.$emit(this.isFocused ? 'focus' : 'blur');
      },
    },
    isPopupShowing: {
      handler() {
        this.$emit(this.isPopupShowing ? 'popup-show' : 'popup-hide');
      },
    },
    maxGrownHeight: {
      handler() {
        this.updateMaxGrownHeight();
      },
    },
    relevantImageData: {
      async handler(value) {
        if (value) {
          await this.showImagePopupWithRelevantData(value);
        }
      },
    },
  },
  methods: {
    selectAll() {
      this.quill.setSelection(0, this.quill.getLength());
    },
    focus() {
      this.quill.focus();
    },
    blur() {
      this.quill.blur();
    },
    restoreSelectionNextTick() {
      const range = this.quill.getSelection();
      if (range) {
        this.$nextTick(() => {
          this.quill.setSelection(range);
        });
      }
    },
    handleFocus() {
      this.isFocused = true;
    },
    handleBlur() {
      if (!this.isPopupShowing) {
        this.isFocused = false;
      }
    },
    ready(quill) {
      this.quill = quill;
      [this.toolbar] = this.$refs.quillEditor.$el.getElementsByClassName('ql-toolbar');
      [this.editor] = this.$refs.quillEditor.$el.getElementsByClassName('ql-editor');
      this.setupTooltips();

      this.updateMaxGrownHeight();

      registerMedia(this.quill, this.insertImage, this.insertVideo);
      registerCustomLink(this.quill, this.getUrl);
    },
    updateMaxGrownHeight() {
      if (this.editor) {
        this.editor.style.maxHeight = this.maxGrownHeight > 0
          ? `${this.maxGrownHeight}px`
          : '100%';
      }
    },
    setupTooltips() {
      const classTitlesPairs = [
        { class: 'ql-color-picker', title: 'Color' },
        { class: 'ql-bold', title: 'Bold' },
        { class: 'ql-italic', title: 'Italic' },
        { class: 'ql-strike', title: 'Strikethrough' },
        {
          class: 'ql-script',
          titles: {
            sub: 'Subscript',
            super: 'Superscript',
          },
        },
        ...MediaToolbarButtonsTooltips,
        ...VoiceToolbarButtonTooltips,
        CustomLinkToolbarButtonTooltip,
      ];

      for (let i = 0; i < classTitlesPairs.length; i += 1) {
        const classTitlesPair = classTitlesPairs[i];
        const elements = this.$refs.quillEditor.$el.getElementsByClassName(classTitlesPair.class);
        if (classTitlesPair.title) {
          const element = elements[0];
          if (element) {
            element.setAttribute('title', classTitlesPair.title);
          }
        } else if (classTitlesPair.titles) {
          for (let j = 0; j < elements.length; j += 1) {
            const element = elements[j];
            element.setAttribute('title', classTitlesPair.titles[element.getAttribute('value')]);
          }
        }
      }
    },

    async insertImage(inserter) {
      const imageMediaData = await this.showEditMediaPopup(MediaData.createImage({}));
      if (imageMediaData) {
        inserter(imageMediaData.attributes);
      }
    },
    async insertVideo(inserter) {
      const videoMediaData = await this.showEditMediaPopup(MediaData.createVideo({}));
      if (videoMediaData) {
        inserter(videoMediaData.attributes);
      }
    },
    async editMedia({ detail }) {
      if (!this.isFocused) {
        this.isFocused = true;
      }
      const { mediaData, update } = detail;
      if (mediaData.isImage) {
        this.postponedUpdate = update;
        this.$emit('get-relevant-image-data', mediaData);
      } else {
        const newMediaData = await this.showEditMediaPopup(mediaData);
        if (newMediaData) {
          update(newMediaData);
        }
      }
    },
    async showImagePopupWithRelevantData(mediaData) {
      const newMediaData = await this.showEditMediaPopup(mediaData);
      if (newMediaData) {
        this.postponedUpdate(newMediaData);
        this.postponedUpdate = null;
        this.$emit('update:relevantImageData', null);
      }
    },
    async showEditMediaPopup(mediaData) {
      let newMediaData = null;
      try {
        this.isPopupShowing = true;
        newMediaData = await this.$refs.editMediaPopup.show(mediaData);
      } finally {
        this.isPopupShowing = false;
        this.focus();
      }

      return newMediaData;
    },
    async getUrl(text) {
      const newLinkData = await this.showEditCustomLinkPopup(text, '', { header: 'Add Link', readonlyText: true });
      return newLinkData ? newLinkData.url : null;
    },
    async editCustomLink({ detail }) {
      if (!this.isFocused) {
        this.isFocused = true;
      }

      const { text, url, update } = detail;
      const newLinkData = await this.showEditCustomLinkPopup(text, url, { header: 'Edit Link', allowRemoveLink: true });
      if (newLinkData) {
        update(newLinkData.text, newLinkData.url);
      }
    },
    async showEditCustomLinkPopup(text, url, options) {
      let newLinkData = null;
      try {
        this.isPopupShowing = true;
        newLinkData = await this.$refs.editCustomLinkPopup.show(text, url, options);
      } finally {
        this.isPopupShowing = false;
        this.focus();
      }

      return newLinkData;
    },
  },
};
</script>

<style lang="scss">
  @import 'quill/dist/quill.core';
  @import 'quill/dist/quill.snow';
  @import 'quill/dist/quill.bubble';
  @import './media/media';
  @import './voice/voice';

  .rich-text-editor {
    &.rich-text-editor-disabled {
      .ql-editor > * {
        cursor: default;
      }
    }

    // fix color picker (https://github.com/quilljs/quill/issues/2172)
    .ql-toolbar {
      .ql-picker-label {
        user-select: none;
      }

      .ql-picker-item {
        user-select: none;
      }
    }
    &:not(.rich-text-editor-focused), &.rich-text-editor-disabled {
      .ql-toolbar {
        transform: scale(0.5) translateY(28px);
        transform-origin: left;
        -webkit-transform-origin-x: left;
        background-color: #252525;
        button, .ql-stroke {
          stroke: #ccc;
        }
        .ql-formats {
          position: relative;
          &:before {
            content: "";
            display: block;
            background: transparent;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            z-index: 1;
          }
        }
      }
    }
    .ql-toolbar {
      position: absolute;
      background-color: #ccc;
      padding: 0;
      margin-top: -24px;
      border: none;
      border-radius: 3px;
      height: 23px;
      min-width: var(--toolbar-min-width);
      transition: transform 0.2s;;

      .ql-formats {
        height: 21px;
        margin: 1px;
        vertical-align: baseline;

        > button, > .ql-color-picker {
          height: 21px;
          width: 26px;
        }

        > .ql-color-picker {
          &.ql-expanded {
            .ql-picker-label {
              outline: none;

              .ql-stroke {
                stroke: #888;
              }
            }
          }

          svg {
            float: left;
          }
        }

        .ql-picker-options {
          width: 172px;
          background-color: #ccc;
          border: none;
        }
      }
    }

    .ql-container {
      border: 0;

      .ql-editor {
        height: 100%;
        width: 100%;
        font-size: 14px;
        font-family: "Inter UI";
        line-height: 1.5;
        padding: 6px;
      }
    }
  }
</style>
