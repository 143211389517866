export const REGEX_FILE_EXT = /(?:\.([^.]+))?$/;
export const REGEX_FILENAME = /[^/\\&?]+\.\w{3,4}(?=([?&].*$|$))/;
export const REGEX_SIZE = /\.x\d{2,4}\./;
export const NUMERIC_DEFAULT_SIZES = [1024, 512, 256, 128, 64];

export const ATTRIBUTE = {
  src: 'src',
  fileSrc: 'filesrc',
  id: 'id',
  title: 'title',
  altText: 'alttext',
  public: 'public',
  width: 'width',
  height: 'height',
  autoPlay: 'autoplay',
  autoRepeat: 'autorepeat',
  timeVisible: 'timevisible',
  pausable: 'pausable',
  sizes: 'sizes',
  existingSrc: 'existingsrc',
};

export const FIELD_TYPE = {
  string: 'string',
  number: 'number',
  boolean: 'boolean',
  file: 'file',
  list: 'list',
};

export const TAG = {
  image: 'img',
  video: 'video',
};
